import {
    addPartNumber,
    editPartNumber
} from "./actions.js"

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import documents from "../Documents/attachable.js"

const dataFields = [{
    "label": "Part Number",
    "key": "Number"
}, {
    "label": "Manufacturer",
    "key": "Manufacturer",
    "link": "Database/Manufacturers/:ManufacturerID"
}, {
    "label": "Description",
    "key": "Description",
    "class": "md:col-span-3"
}];

export default [{
    "path": "/Database/PartNumbers",
    "component": { 
        ...ItemListView
    },
    "name": "Part Numbers",
    "meta": {
        "app": "Database",
        "name": "Part Numbers",
        "sideNavSection": "Misc",
        "sideNav": true,
        "icon": "database",
        "tableSettings": "EAFs",
        "dataPath": "Database/PartNumbers",
        "selectionRoute": "Database/PartNumbers/{ID}",
        "menuActions": [{
            "label": "Add Part Number",
            "action": addPartNumber
        }],
        filter(item) {
            return item.Cancelled !== true
        }
    }
}, {
    "path": "/Database/PartNumbers/:id",
    "component": ItemView,
    "name": "Part Number",
    "meta": {
        "app": "Database",
        "name": "Part Numbers",
        "dataPath": "Database/PartNumbers",
        "selectionRoute": "Database/PartNumbers/{ID}",
        title(item) {
            return `Commercial: ${item?.Number}`
        },
        "attachedItems": {
            "Links" : {
                "attachables": [documents]
            }
        },
        "formMetadata": {
            "ParentType": "PartNumbers"
        },
        "dataFields": dataFields,
        "menuActions": [{
            "label": "Edit Part Number",
            "action": editPartNumber
        }]
    }
}];
