import {
    appConfig,
    makeAttached,
    makeDropdown,
    pad
} from "../../../utils.js";

export const addDQN = ({ form, pageItem, store, attachedItems, meta }) => {
    form.setConfig({
        "title": "Add DQN",
        "type": "push",
        "location": "Database/DQNs",
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}DQN-${pad(store.state.counts.database.dqn.count)}`
            }
        }, {
            "label": "Comment",
            "name": "Comment",
            "type": "text",
            "divClass": "col-span-2"
        }, {
            "label": "Select Documents",
            "name": "Documents",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "showSelectAll": true,
            "dropdownData": makeDropdown(store, attachedItems.Documents.items, "Number", "ID")
        }, {
            "label": "Select Drawings",
            "name": "Drawings",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Drawings.items, "Number", "ID")
        }, {
            "label": "Select Effectivities",
            "name": "Effectivities",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Effectivities.items, "Number", "ID")
        }, {
            "label": "Reason",
            "name": "Reason",
            "type": "dropdown",
            "required": true,
            "dataLocation": "Database/DQNReasons",
            "labelKey": "Name",
            "idKey": "Name"
        }],
        preSubmitHook({ data }) {
            return {
                "Date": {
                    "Created": new Date().toISOString(),
                    "Cancelled": "N/A",
                    "Signedoff": "N/A"
                },
                "Cancelled": false,
                "Signedoff": false,
                "Author": store.getters.userName,
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "ParentType": meta.ParentType,
                "Number": `${appConfig.docPrefix}DQN-${pad(store.state.counts.database.dqn.count)}`,
                "Documents": makeAttached(data.Documents),
                "Drawings": makeAttached(data.Drawings),
                "Effectivities": makeAttached(data.Effectivities),
                [meta.ParentType]: {
                    [pageItem.ID]: true
                }
            }
        },
        postSubmitHook({ db, data, typeRef }) {
            data.ID = typeRef.key;
            db.set(`Database/${meta.ParentType}/${pageItem.ID}/DQNs/${data.ID}`, true);
            db.utils.addAllTo(data, "Database/Documents", "Documents", "DQNs");
            db.utils.addAllTo(data, "Database/Drawings", "Drawings", "DQNs");
            db.utils.addAllTo(data, "Database/Effectivities", "Effectivities", "DQNs");
            db.utils.upCount("counts/database/dqn/count");
            db.utils.updateRefs(data, "Database/Refs/DQNs", ["Number", "ID", "Comment", "Signedoff", "Date", "Parent", "ParentID"]);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editDQN = async ({ form, item, attachedItems, store }) => {
    form.setValues({
        "Comment": item.Comment,
        "Documents": Object.keys(item.Documents || {}),
        "Drawings": Object.keys(item.Drawings || {}),
        "Effectivities": Object.keys(item.Effectivities || {}),
        "Reason": item.Reason
    });
    form.setConfig({
        "title": "Update DQN",
        "type": "update",
        "location": `Database/DQNs/${item.ID}`,
        "set": true,
        "fields": [{
            "label": "Comment",
            "name": "Comment",
            "type": "text",
            "divClass": "col-span-3"
        }, {
            "label": "Select Documents",
            "name": "Documents",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "showSelectAll": true,
            "dropdownData": makeDropdown(store, attachedItems.Documents.items, "Number", "ID")
        }, {
            "label": "Select Drawings",
            "name": "Drawings",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Drawings.items, "Number", "ID")
        }, {
            "label": "Select Effectivities",
            "name": "Effectivities",
            "type": "dropdown",
            "multiple": true,
            "required": false,
            "dropdownData": makeDropdown(store, attachedItems.Effectivities.items, "Number", "ID")
            }, {
                "label": "Reason",
                "name": "Reason",
                "type": "dropdown",
                "required": true,
                "dataLocation": "Database/DQNReasons",
                "labelKey": "Name",
                "idKey": "Name"
            }],
        preSubmitHook({ data }) {
            return {
                "Documents": makeAttached(data.Documents),
                "Drawings": makeAttached(data.Drawings),
                "Effectivities": makeAttached(data.Effectivities)
            }
        },
        postSubmitHook({ db, data, typeRef }) {
            db.utils.addAllTo(data, "Database/Documents", "Documents", "DQNs");
            db.utils.addAllTo(data, "Database/Drawings", "Drawings", "DQNs");
            db.utils.addAllTo(data, "Database/Effectivities", "Effectivities", "DQNs");
            db.utils.updateRefs(data, "Database/Refs/DQNs", ["Number", "ID", "Comment", "Signedoff", "Date", "Parent", "ParentID"]);
        }
    });
    await form.getDropdownData();

    form.toggle();
};

export const cancelDQN = ({ form, item }) => {
    form.setConfig({
        "title": "Cancel DQN",
        "description": `Are you sure you want to cancel DQN ${item.Number}?`,
        "type": "update",
        "location": `Database/DQNs/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Cancelled": true,
                "Date/Cancelled": new Date().toISOString()
            }
        },
        postSubmitHook({ db }) {
            db.utils.updateRefs(data, "Database/Refs/DQNs", ["Number", "ID", "Comment", "Signedoff", "Date", "Parent", "ParentID"]);
        }
    });
    form.toggle();
};

export const signoffDQN = ({ form, item }) => {
    form.setConfig({
        "title": "Signoff DQN",
        "description": `Are you sure you want to signoff DQN ${item.Number}?`,
        "type": "update",
        "location": `Database/DQNs/${item.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Signedoff": true,
                "Date/Signedoff": new Date().toISOString()
            }
        },
        postSubmitHook({ db, newData }) {
            db.utils.updateRefs(newData, "Database/Refs/DQNs", ["Number", "ID", "Comment", "Signedoff", "Date", "Parent", "ParentID"]);
        }
    });
    form.toggle();
};

export const linkDQN = ({ form, pageItem, meta }) => {
    form.setConfig({
        "title": "Link DQN",
        "type": "update",
        "location": `Database/${meta.ParentType}/${pageItem.ID}`,
        "fields": [{
            "label": "DQN",
            "name": "DQN",
            "type": "dropdown",
            "labelKey": "Number",
            "idKey": "ID",
            "dataLocation": "Database/Refs/DQNs",
            "search": true
        }],
        preSubmitHook({ liveInputData }) {
            return {
                "DQN": null,
                [`DQNs/${liveInputData.DQN}`]: true
            }
        },
        postSubmitHook({ db, liveInputData }) {
            db.update(`Database/DQNs/${liveInputData.DQN}/${meta.ParentType}/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();

    form.toggle();
};

export const unlinkDQN = ({ form, rowItem, pageItem, toast, meta }) => {
    if (rowItem.ParentID === pageItem.ID) {
        toast.error("You cannot unlink this DQN from this item.");
        return;
    }
    form.setConfig({
        "title": "Unlink DQN",
        "location": `Database/${meta.ParentType}/${pageItem.ID}/DQNs/${rowItem.ID}`,
        "type": "delete",
        "fields": []
    });
    form.toggle();
};
