import {
    cancelRepair,
    completeRepair,
    editRepair
} from "./actions";
import {
    thirdPartyDocs,
    thirdPartyDraws,
    thirdPartyOther
} from "../Miscellaneous/attachable.js";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import arrAttachable from "../Arrangements/attachable.js";
import commercialsAttachable from "../Commercials/attachable.js";
import concessionAttachable from "../Concessions/attachable.js";
import documentsAttachable from "../Documents/attachable.js";
import doiAttachable from "../DOIs/attachable.js";
import dqnsAttachable from "../DQNs/attachable.js";
import drawingAttachable from "../Drawings/attachable.js";
import effectivitiesAttachable from "../Effectivities/attachable.js";
import jobCardsAttachable from "../JobCards/attachable.js";
import { repairItemsReport } from "./reports";
import saddAttachable from "../SADDs/attachable.js";
import sbAttachable from "../ServiceBulletins/attachable.js";

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Primary Commercial / Job Number",
    "key": "Parent",
    "link": "Database/Commercials/:ParentID"
}, {
    "label": "Responsible Engineer",
    "key": "ResponsibleEngineer"
}, {
    "label": "Customer",
    "key": "Customer"
}, {
    "label": "Major Repair Approval",
    "key": "EASANumber"
}, {
    "label": "Mod Type",
    "key": "Type"
}, {
    "label": "Date Created",
    compute({item}) {
        let d = new Date(item.Date.Created);
        return d.toLocaleDateString("en-GB", {
            "year": 'numeric', "month": 'long', "day": 'numeric'
        })
    }
}, {
    "label": "Date Completed",
    compute({item}) {
        let d = new Date(item.Date.Completed);
        return d.toLocaleDateString("en-GB", {
            "year": 'numeric', "month": 'long', "day": 'numeric'
        })
    }
}, {
    "label": "Title",
    "key": "Title",
    "class": "md:col-span-3"
}];

const menuActions = [
    {
        "label": "Download Report",
        "break": true,
        "action": repairItemsReport
    },
    {
        "label": "Edit Repair",
        "action": editRepair
    },
    {
        "label": "Complete Repair",
        "action": completeRepair,
        show({ attachedItems, pageItem }) {
            if (pageItem.Completed) return [false, 'The Repair has already been completed'];
            if (!pageItem.Effectivities) return [false, 'This Repair does not have any Effectivities'];

            let warnings = Object
                .values(attachedItems)
                .filter(ai => ai.warn[0])
                .map(ai => `${ai.label}<br>${ai.warn[1]}`)
                .join("<br>");

            if (warnings.length > 0) return [false, warnings];
            else return [true, ''];
        }
    },
    {
        "label": "Cancel Repair",
        "class": "text-red",
        "action": cancelRepair,
        "break": true
    }
];

export default [{
    "path": "/Database/Repairs",
    "component": {...ItemListView},
    "name": "Repairs",
    "meta": {
        "app": "Database",
        "name": "Repairs",
        "sideNavSection": "Designs",
        "sideNav": true,
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "Repairs",
        "dataPath": "Database/Repairs",
        "storeData": ["Database/DocumentTypes"],
        "selectionRoute": "Database/Repairs/{ID}"
    }
}, {
    "path": "/Database/Repairs/:id",
    "component": ItemView,
    "name": "Repair",
    "meta": {
        "app": "Database",
        "name": "Repairs",
        "dataPath": "Database/Repairs",
        "selectionRoute": "Database/Repairs/{ID}",
        "storeData": ["Database/DocumentTypes"],
        title(item) {
            return `Repairs: ${item.Number}`
        },
        "attachedItems": {
            "Design Items": {
                "attachables": [documentsAttachable, drawingAttachable, saddAttachable, effectivitiesAttachable]
            },
            "Design Queries": {
                "attachables": [concessionAttachable, dqnsAttachable, sbAttachable, doiAttachable]
            },
            "Project Items": {
                "attachables": [commercialsAttachable, jobCardsAttachable, arrAttachable, thirdPartyDocs,
                    thirdPartyDraws,
                    thirdPartyOther]
            }
        },
        "formMetadata": {
            "ParentType": "Repairs"
        },
        "dataFields": dataFields,
        "menuActions": menuActions
    }
}];
