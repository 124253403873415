import { completeEadt, eadtFields, editEadtData } from "./actions";

import ItemListView from "../../../components/ItemListView/ItemListView.vue";
import ItemView from "../../../components/ItemView/ItemView.vue";
import partNumberAttachable from "../ApprovedPartNumbers/attachable.js";

console.log(partNumberAttachable);

const datasheetsAttachable = {
    "label": "Datasheets",
    "key": "Datasheets",
    "local": true,
    "headers": [{
        "headerName": "File",
        "field": "File",
    }],
    "actions": [{
        "label": "Attach Datasheet",
        action({ form, pageItem }) {
            form.setConfig({
                "title": "Attach Datasheet",
                "type": "push",
                "location": `Database/Documents/${pageItem.ID}/Datasheets`,
                "fields": [{
                    "label": "Datasheet",
                    "name": "File",
                    "type": "file"
                }],
                preSubmitHook({ files }) {
                    return {
                        "File": files[0][0].name
                    };
                },
                async postSubmitHook({ files, db, typeRef }) {
                    if (files.length > 0 && files[0].length < 1) return;
                    for (const file of files) {
                        await db.upload(`Database/Documents/${pageItem.ID}/Datasheet`, file[0]);
                    }
                }
            });
            form.toggle();
        }
    }],
    "rowActions": [{
        "label": "Download",
        async action({ pageItem, rowItem, db }) {
            const url = await db.download(`Database/Documents/${pageItem.ID}/Datasheet/${rowItem.File}`);
            window.open(url, "_blank");
        }
    }]
};


export default [{
    "path": "/Database/EADTs",
    "component": { ...ItemListView },
    "name": "EADTs",
    "meta": {
        "app": "Database",
        "name": "EADTs",
        "sideNav": true,
        "sideNavSection": "Design Items",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "EADTs",
        "dataPath": "Database/Refs/Documents",
        "selectionRoute": "Database/EADTs/{ID}",
        filter(item) {
            return item.Number.includes(".EADT-");
        }
    }
}, {
    "path": "/Database/EADTs/:id",
    "component": ItemView,
    "name": "EADT",
    "meta": {
        "app": "Database",
        "name": "EADT",
        "dataPathOverride": "Database/Documents/:id",
        title(item) {
            return item.Name;
        },
        "menuActions": [{
            "label": "Update EADT Data",
            "action": editEadtData,
            show({ pageItem }) {
                return [pageItem.Completed !== true, "EADT is completed."];
            }
        }, {
            "label": "Complete EADT",
            "action": completeEadt,
            show({ pageItem }) {
                return [pageItem.Completed !== true, "EADT is completed."];
            }
        }],
        "dataFields": [{
            "label": "Number",
            "key": "Number"
        }, {
            "label": "Parent",
            "key": "Parent",
            "link": "Database/:ParentType/:ParentID"
        }, {
            "label": "Is Completed",
            "key": "Completed",
            "type": "bool"
        },{
            "label": "Date Completed",
            "key": "Date.Completed",
            "type": "date"
        }, {
            "label": "Title",
            "key": "Title",
            "class": "md:col-span-3"
        }, ...eadtFields],
        "formMetadata": {
            "ParentType": "Documents"
        },
        "attachedItems": {
            "Items": {
                "attachables": [datasheetsAttachable, partNumberAttachable]
            }
        },
        async getLinkedData({ db, pageItem }) {
            const partNumber = await db.once(`Database/PartNumbers/${pageItem.PartNumberID}`);
            const manufacturer = await db.once(`Database/Manufacturers/${partNumber.ManufacturerID}`);
            return {
                PartNumber: partNumber.Number,
                Manufacturer: manufacturer.Name,
                ManufacturerID: manufacturer.ID,
                Name: partNumber.Description
            };
        }
    }
}];
