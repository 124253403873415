import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import documentsAttachable from "../Documents/attachable.js";
import drawingAttachable from "../Drawings/attachable.js";
import effectivitiesAttachable from "../Effectivities/attachable.js";
import serviceBulletinAttachable from "../ServiceBulletins/attachable.js";
import { time } from "../../standardAttachables";

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Parent",
    "key": "Parent",
    "link": "Database/:ParentType/:ParentID"
}, {
    "label": "Is Resolved?",
    compute( { item } ) {
        return `<span class="font-bold ${item.Signedoff ? 'text-green' : 'text-red'}">${item.Signedoff ? 'Resolved' : 'Unresolved'}</span>`
    }
}, {
    "label": "Reason",
    "key": "Reason"
},{
    "label": "Comment",
    "key": "Comment",
    "class": "md:col-span-3"
}];

const menuActions = [
    // {
    //     "label": "Edit Document",
    //     "action": editDocument
    // },
    // {
    //     "label": "Cancel Document",
    //     "class": "text-red",
    //     "action": cancelDocument
    // }
];

export default [{
    "path": "/Database/DQNs",
    "component": {...ItemListView},
    "name": "DQNs",
    "meta": {
        "app": "Database",
        "name": "DQNs",
        "sideNav": true,
        "sideNavSection": "Design Items",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "DQNs",
        "dataPath": "Database/Refs/DQNs",
        "selectionRoute": "Database/DQNs/{ID}"
    }
}, {
    "path": "/Database/DQNs/:id",
    "component": ItemView,
    "name": "DQN",
    "meta": {
        "app": "Database",
        "name": "DQNs",
        "dataPath": "Database/DQNs",
        "selectionRoute": "Database/DQNs/{ID}",
        title(item) {
            return `DQNs: ${item.Number}`
        },
        "attachedItems": {
            "Attached Items": {
                "attachables": [documentsAttachable, drawingAttachable, effectivitiesAttachable, serviceBulletinAttachable]
            },
            "Additional Information": {
                "attachables": [time]
            }
        },
        "preventAttachedItemsActions": false,
        "preventAttachedItemsRowActions": false,
        "dataFields": dataFields,
        "menuActions": menuActions,
        "formMetadata": {
            "ParentType": "DQNs"
        }
    }
}];
