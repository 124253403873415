import {
    addDocument,
    cancelDocument,
    changeLinkedRevision,
    checkDocument,
    editDocument,
    generateDocuemnt,
    linkDocument,
    makePrototype,
    reviseDocument,
    reviseDrawingToA,
    revisionRollback,
    unlinkDocument
} from "./actions";

import { appConfig } from "../../../utils";
import {
    get
} from "lodash";
import store from "../../../store";
import { updateUploadedStatus } from "./actions";

function warning(key) {
    let item = store.state[key];
    if (!item) return false;
    if(item.Cancelled) return false;

    let warningMessage = "";
    if (!item?.Revisions) return "No revision's found";

    if (!item?.Revisions[item.RevisionID]?.Quality?.Approver?.Completed && !item.Cancelled) {
        warningMessage = warningMessage += `${item.Number} REV ${item.Revision} not complete.<br>`;
    }
    if (item.Revision.toString().includes("P")) {
        warningMessage = warningMessage += `${item.Number} at a prototype revision.<br>`;
    }
    return warningMessage === "" ? false : warningMessage;
}

const actions = [{
    "label": "Add Document",
    "action": addDocument,
    show({ meta }) {
        return [["DesignChanges", "FlightConditions", "Documents", "Repairs"].includes(meta.ParentType), "New Documents cannot be added to this, link an existing Document"]
    }
}, {
    "label": "Link Document",
    "action": linkDocument
}];

const cellClassRules = {
    "text-orange font-bold": params => {
        return warning(params.data);
    }
}

const headers = [{
    "headerName": "Number",
    "field": "Number",
    "sort": "asc",
    cellClassRules
}, {
    "headerName": "Title",
    "field": "Title",
    cellClassRules
}, {
    "headerName": "Linked Revision",
    cellClassRules,
    fieldGetter({ pageItem }) {
        return `Links.${pageItem.ID}`
    },
    valueFormatter(params) {
        let linkedRev = get(params.data, params.column.colId);
        let revision = get(params.data, `Revisions.${linkedRev}`);
        if (!!revision) {
            return revision.Revision === params.data.Revision ? revision.Revision : `${revision.Revision} (Revision ${params.data.Revision} Available)`
        } else return params.data.Revision
    }
}, {
    "headerName": "Revision Completed",
    "type": "bool",
    fieldGetter({ pageItem }) {
        return `Links.${pageItem.ID}`
    },
    valueFormatter(params) {
        let linkedRev = get(params.data, params.column.colDef.field, params.data.RevisionID);
        return get(params.data, `Revisions.${linkedRev}.Quality.Approver.Completed`, false);
    }
}];

const rowActions = [{
    "label": "View Document Details",
    action({ router, rowItem }) {
        router.push(`/Database/Documents/${rowItem.ID}`)
    }
}, {
    "label": "Edit Document",
    "action": editDocument,
    "break": true
}, {
    "label": "Revise Document",
    "action": reviseDocument,
    show({ item }) {
        return [item.Revisions[item.RevisionID]?.Quality?.Approver?.Completed === true, "Revision not completed"];
    }
}, {
    "label": "Rollback Revision",
    "action": revisionRollback,
    show({ item }) {
        return [Object.keys(item?.Revisions || {}).length > 1, "No previous revisions found"];
    }
}, {
    "label": "Change Linked Revision",
    "action": changeLinkedRevision,
    show({ item }) {
        return [Object.keys(item?.Revisions || {}).length > 1, "No previous revisions found"];
    }
}, {
    "label": "Revise to Prototype",
    "action": makePrototype,
    "break": true,
    show({ item }) {
        return item.Revision.toString().includes("P") ? [false, "Revision already a prototype"] : [true, ""];
    }
}, {
    "label": "Revise from Prototype",
    "action": reviseDrawingToA,
    show({ item }) {
        return item.Revision.toString().includes("P") ? [true, ""] : [false, "Revision not at prototype"];
    }
}, {
    "label": "Set Revision as Complete",
    "action": checkDocument("Approver"),
    "break": true,
    show({ item, store }) {

        if(item.Type === "EAF") {
            return [!!item.PartNumbers, "Part Numbers not set"]
        }
        return [item.Revisions[item.RevisionID].Quality.Approver.Completed === false, "Revision already completed"];
    }
}, {
    "label": "Unlink Document",
    "break": true,
    "action": unlinkDocument,
    show({ rowItem, pageItem }) {
        return [rowItem.ParentID !== pageItem.ID, "Cannot unlink from the main parent"]
    }
}, {
    "label": "Generate Document",
    "breal": true,
    "action": generateDocuemnt,
    "show": ({ item, store }) => {
        const type = store.state["Database/DocumentTypes"][item.TypeID];
        console.log(type);
        
        return !!type.Revisions[type.RevisionID].GeneratableFile ? [true, ""] : [false, "Generatable file not uploaded"];
    }
},{
    "label": "Cancel Document",
    "action": cancelDocument,
    "class": "font-bold text-red",
    "break": true
}];

if(appConfig.appPrefix !== "stc") {
    rowActions.splice(rowActions.length - 2, 0, {
        "label": "Update Upload Status",
        "action": updateUploadedStatus,
        "break": true
    });

    headers.push({
        "headerName": "Uploaded Status",
        "field": "UploadStatus",
        valueFormatter: params => {
            return params?.data?.UploadStatus || "Not Uploaded";
        },
        cellClassRules
    });
};

export default {
    "label": "Documents",
    "key": "Documents",
    "location": "Database/Documents/",
    actions,
    rowActions,
    headers,
    warn({ items }) {
        let warningMessage = items.map(warning).filter(i => i !== false).join("<br>");
        return [warningMessage !== "", warningMessage];
    }
};
