const isEADT = ({ liveInputData }) => {
    return ["EADT"].includes(liveInputData.Type);
};

export const eadtFormFields = [{
    "label": "Item Name",
    "name": "Name",
    "type": "text",
    "formStep": 1,
},{
    "label": "Part Number",
    "name": "PartNumberID",
    "type": "dropdown",
    "dataLocation": "Database/PartNumbers",
    "idKey": "ID",
    "labelKey": "Number",
    "formStep": 1,
    "show": isEADT
}, {
    "label": "Description",
    "name": "Description",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "formStep": 1,
    show({ liveInputData }) {
        return ["EAF", "EADT"].includes(liveInputData.Type);
    }
}, {
    "label": "Datasheet Upload",
    "type": "file",
    "name": "Datasheet",
    "divClass": "md:col-span-3",
    "show": isEADT,
    "formStep": 1,
    "required": false
}, {
    "label": "Qualification",
    "name": "Qualification",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "formStep": 2,
    "show": isEADT
}, {
    "label": "Limitations",
    "name": "Limitations",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "formStep": 3,
    "show": isEADT
}, {
    "label": "Additional Data",
    "name": "AdditionalData",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "formStep": 4,
    "show": isEADT
}, {
    "label": "Required Maintenance",
    "name": "RequiredMaintenance",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "formStep": 5,
    "show": isEADT
}, {
    "label": "Suitability",
    "name": "Suitability",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "formStep": 6,
    "show": isEADT
}, {
    "label": "Documentation",
    "name": "Documentation",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "formStep": 7,
    "show": isEADT
}, {
    "label": "Test / Setup Requirements",
    "name": "TestRequirements",
    "divClass": "md:col-span-3",
    "type": "richtext",
    "formStep": 8,
    "show": isEADT
}];

export const eadtFields = [{
    "label": "Manufacturer",
    "key": "Manufacturer",
    "grow": true,
    "link": "Database/Manufacturers/:ManufacturerID"
}, {
    "label": "Item Name",
    "key": "Name",
    "grow": true
}, {
    "label": "Description",
    "key": "Description",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Qualification",
    "key": "Qualification",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Limitations",
    "key": "Limitations",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Additional Data",
    "key": "AdditionalData",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Required Maintenance",
    "key": "RequiredMaintenance",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Suitability",
    "key": "Suitability",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Documentation",
    "key": "Documentation",
    "grow": true,
    "class": "md:col-span-3"
}, {
    "label": "Test / Setup Requirements",
    "key": "TestRequirements",
    "grow": true,
    "class": "md:col-span-3"
}];

export function editEadtData({ item, db, form }) {
    form.setValues({
        ...item,
        "Datasheet": null
    });
    form.setConfig({
        "title": "Revise EADT",
        "location": `Database/Documents/${item.ID}/`,
        "type": "update",
        "isSteppedForm": true,
        "fields": eadtFormFields,
        async onStepChange({ files }) {
  
            
            if(files.length > 0 && files[0].length < 1) return;
            for (const file of files) {
                await db.upload(`Database/Documents/${item.ID}/Datasheet`, file[0]);
                await db.push(`Database/Documents/${item.ID}/Datasheets`, {
                    "File": file[0].name
                });          
            }
        },
        async preSubmitHook({ liveInputData, dropdownData }) {
            
            let pn = dropdownData.PartNumberID.find(i => i.id === liveInputData.PartNumberID);

            await db.update(`Database/PartNumbers/${pn.id}`, {
                "Documents":{
                    [item.ID]: true
                }
            });
            
            return {
                "PartNumber": pn.label,
                "ManufacturerID": pn.meta.ManufacturerID,
                "PartNumbers": {
                    [liveInputData.PartNumberID]: true
                }
            }
        }
    });
    form.getDropdownData();
    form.toggle();
}

export const completeEadt = ({ item, db, form }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Complete EADT",
        "location": `Database/Documents/${item.ID}/`,
        "description": "Are you sure you want to complete this EADT?",
        "type": "update",
        "fields": [],
        async preSubmitHook({ files }) {
            return {
                "Completed": true,
                "Date": {
                    "Completed": new Date().toISOString()
                }
            }
        }
    });
    form.toggle();
};


