export const addDQNReason = ({ form }) => {
    form.setConfig({
        "title": "Add DQN Reason",
        "type": "push",
        "location": "Database/DQNReasons",
        "fields": [{
            "label": "Reason",
            "name": "Name",
            "type": "text"
        }]
    });
    form.toggle();
}

export const editDQNReason = ({ form, rowItem }) => {
    form.setValues(rowItem);
    form.setConfig({
        "title": "Edit DQN Reason",
        "type": "update",
        "location": `Database/DQNReasons/${rowItem.ID}`,
        "fields": [{
            "label": "Reason",
            "name": "Name",
            "type": "text"
        }]
    });
    form.getDropdownData();
    form.toggle();
}
