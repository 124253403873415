import { editPartNumber, linkPartNumber } from "./actions"

export default {
    "label": "Part Numbers",
    "key": "PartNumbers",
    "location": "Database/PartNumbers/",
    "actions": [{
        "label": "Link Part Number",
        "action": linkPartNumber
    }],
    "headers": [{
        "headerName": "Number",
        "field": "Number"
    }, {
        "headerName": "Description",
        "field": "Description"
    }],
    "rowActions": [{
        "label": "Open Part Number",
        action({ router, rowItem }) {
            router.push(`/Database/PartNumbers/${rowItem.ID}`)
        }
    }, {
        "label": "Edit Part Number",
        "action": editPartNumber
    }],
    show({ pageItem, router }) {
        return ["EAR", "EAF", "EADT"].includes(pageItem.Type) || router.currentRoute.value.path.includes("Manufacturers")
    }
}
