import {
    addDQNReason,
    editDQNReason
} from "./actions";

export default {
    "label": "DQN's Reasons",
    "key": "DrawingTypes",
    "location": "Database/DQNReasons/",
    "headers": [{
        "headerName": "Reason",
        "field": "Name"
    }],
    "actions": [{
        "label": "Add Type",
        "action": addDQNReason
    }],
    "rowActions": [{
        "label": "Edit Type",
        "action": editDQNReason
    }]
}
